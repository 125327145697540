import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import Slider from './3d-slider-vanzolini'
import Slider2 from './3d-slider-comerc'
import Slider3 from './3d-slider-renova'
import Slider4 from './3d-slider-cnpq1'
import Slider5 from './3d-slider-cnpq2'
import Slider6 from './3d-slider-bc'

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['P', 'o', 'r', 't', 'f ', 'o', 'l', 'i', 'o']}
              idx={15}
            />
          </h1>
          <h2>Grupo BC Energia</h2>
          <p>
            Since August 2024, I have been working in the energy sector, assuming 
            a role of significant responsibility that involves various critical 
            tasks. In my position, I leverage my expertise in data analysis, process 
            automation, and predictive modeling to deliver high-quality results and 
            drive informed decision-making.
            <br />
            My key responsibilities include:
            Compiling and Evaluating Assumptions (I am tasked with compiling and evaluating 
            energy, electrical, and hydrological assumptions for PLD (Preço de Liquidação das Diferenças) 
            projection studies. This requires a thorough understanding of the complex factors 
            affecting energy markets to ensure accurate and reliable forecasts); Creating Detailed 
            Reports (I develop comprehensive reports that provide diagnostic and 
            prognostic analyses of electroenergetic and hydrometeorological conditions. My reports 
            are crafted to highlight critical details and trends, enabling stakeholders to make 
            well-informed decisions); Participating in Collaborative Meetings (I actively participate 
            in meetings with sector institutions and internal teams. This collaboration is essential 
            for aligning strategies and ensuring that all perspectives are considered in the 
            decision-making process); Automating Processes and Utilizing Predictive Models (My role 
            involves automating processes and applying predictive models to enhance the efficiency and 
            accuracy of analyses. I am responsible for creating routines for model execution and 
            ensuring the availability of pertinent data, which supports more effective decision-making). 
            <br />
            In this position, I do not work in isolation. Collaboration with teammates is integral to 
            my approach, ensuring that every aspect of the project is addressed comprehensively. 
            This teamwork results in a final product that is both accurate and reliable, meeting 
            the high standards required in the energy sector.
            <br />
            My role is defined by my ability to apply specialized skills in data analysis and 
            process automation, as well as my commitment to working collaboratively to deliver 
            exceptional solutions. My dedication to precision and efficiency underscores my 
            commitment to providing outstanding results in a dynamic and challenging environment.
          </p>
          <br />
          <br />
          <h2>Fundação Carlos Alberto Vanzolini</h2>
          <p>
            I have been working since September 2023, carrying out a role of
            significant responsibility as an independent data entry specialist,
            utilizing my extensive experience in data analysis, report
            interpretation, and effective teamwork to create documents and
            provide services with excellence and efficiency. Through my deep
            understanding of the intricacies and complexities involved in data
            analysis, I am capable of delivering tailor-made and highly
            effective solutions for each project I engage in.
            <br />
            In this position, and in the vast majority of the projects I
            undertake, the core of my responsibilities lies in data collection,
            organization, and interpretation, with the ability to discern
            meaningful patterns and trends being crucial. Furthermore, my report
            analysis is meticulous, highlighting details that often go
            unnoticed, providing a clearer insight into information for
            well-informed decision-making.
            <br />
            However, I do not work in isolation in this process, as
            collaboration is an integral part of my working method. By working
            closely with teammates and stakeholders, I ensure that all aspects
            of the project are addressed comprehensively. This results in a
            high-quality final product that all involved parties can trust.
            <br />
            My role as an independent data entry specialist is characterized by
            the application of specialized skills in data and report analysis,
            as well as my ability to collaborate within a team to provide
            services that not only meet but exceed expectations, offering
            customized and effective solutions for each client and project. My
            dedication to precision and efficiency reflects my ongoing
            commitment to delivering exceptional results in an increasingly
            challenging and dynamic environment.
          </p>
          <br />
          <br />
          <h3>Comerc Energia</h3>
          <p>
            During my time as a Junior Analyst in the Generator Management
            field, spanning from 2021 to 2022, I undertook a range of
            multifaceted responsibilities that played a pivotal role in
            optimizing operations and achieving significant goals. My duties
            encompassed providing regulatory advisory, developing energy
            marketing strategies, delivering personalized customer service, and
            continually enhancing operational processes.
            <br />
            A distinguishing aspect of my performance in this role was the
            introduction of an innovative approach, centered around task
            automation, executed through VBA and Python programming. This
            strategy resulted in substantial gains in operational efficiency,
            offering time and, consequently, resource savings. Task automation
            initiated through my initiatives allowed the team to focus on more
            strategic and high-value activities, while repetitive and
            time-consuming tasks were executed with precision and agility.
            <br />
            Customer service played a pivotal role in maintaining strong
            relationships and ensuring customer satisfaction, guaranteeing that
            their needs were addressed promptly and effectively.
            <br />
            Ultimately, my commitment to the continuous improvement of
            operational processes reflected my dedication to achieving a more
            efficient and effective operation, resulting in a positive impact on
            both team performance and the overall outcomes of the organization.
          </p>
          <br /> <br />
          <h4>Renova Consultoria</h4>
          <p>
            In the year 2021, I had the opportunity to embark on a challenging
            temporary job in which I played a pivotal role in optimizing and
            enhancing processes within a biomass power plant management system.
            My responsibilities encompassed the creation and implementation of
            customized solutions to automate complex tasks and detailed
            calculations, with the aim of significantly improving operational
            efficiency and result accuracy.
            <br />
            One of the essential tools I employed in this endeavor was VBA
            (Visual Basic for Applications), a programming language widely
            recognized for its seamless integration with Microsoft applications
            such as Excel. Through VBA, I was able to develop custom scripts and
            macros that streamlined routine tasks, expedited calculation
            processes, and minimized human errors, resulting in considerable
            time and resource savings.
            <br />
            The nature of the biomass power plant system involved a vast amount
            of data and ntricate mathematical operations. My contribution was
            instrumental in making the system more agile and dependable.
            Furthermore, my capacity to devise tailored solutions enabled the
            team to achieve new levels of efficiency and precision in their
            daily operations.
          </p>
          <br /> <br />
          <h5>PIBIC - CNPq</h5>
          <p>
            Between 2020 and 2021, during my undergraduate studies, I was
            actively engaged as a scholar in a research project aimed at sizing
            photovoltaic systems for the academic buildings of the Federal
            University of São Paulo (UNIFESP) in the Baixada Santista region.
            This endeavor involved the utilization of the Pvsyst software,
            renowned for its effectiveness in the field of solar energy.
            <br />
            In the scope of this project, my responsibilities centered around
            conducting a comprehensive analysis of solar irradiation at each
            strategically chosen location. The primary goal was to design and
            develop efficient photovoltaic systems. Additionally, a critical
            aspect of the project entailed assessing the feasibility of each
            proposed system.
            <br />
            My role required the creation of detailed scenarios and an in-depth
            analysis over the course of a full year. During this period, my task
            was to generate and evaluate essential data, provided by the Pvsyst
            software, based on specific conditions and equipment selected for
            each system's implementation.
            <br />
            This substantial involvement in solar energy research not only
            expanded my knowledge of practical applications of renewable energy
            but also allowed me to make a significant contribution to
            decision-making regarding the adoption of efficient and sustainable
            photovoltaic systems for UNIFESP's academic buildings in the Baixada
            Santista.
          </p>
          <br />
          <br />
          <h6>PIBIC - CNPq</h6>
          <p>
            Between 2018 and 2020, during my undergraduate studies, I had the
            privilege of participating as a scholar in a research project that
            stood out. The focus of this project was on the application of
            advanced data analysis strategies, with an emphasis on creating
            Artificial Neural Networks (ANNs) and their integration with Machine
            Learning for the prediction of environmental data related to solar
            irradiation.
            <br />
            Python was the primary tool used for various critical stages, from
            the meticulous collection of relevant data to the preparation and
            processing of this information. Furthermore, the creation and
            implementation of ANNs for complex predictions were achieved,
            requiring a deep practical understanding of this specialized field.
            <br />
            A highlight of this work included developing a complete artificial
            neural network without depending on specific libraries, resulting in
            a profound practical knowledge of this field. As results and the
            understanding of ANNs solidified, the incorporation of ready-to-use
            libraries became an integral part of the process. These libraries
            enhanced the efficiency in predicting environmental data related to
            solar irradiation.
            <br />
            Moreover, the obtained results were practically applied to fill gaps
            in data during periods when collecting information from
            meteorological stations was not feasible. This contributed to solar
            energy research, offering deep insights into the application of data
            analysis strategies and Machine Learning in an environmental and
            renewable energy context.
          </p>
          <br />
          <br />
        </div>
        <div>
          <Slider6 />
          <Slider />
          <Slider2 />
          <Slider3 />
          <Slider4 />
          <Slider5 />
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Portfolio
