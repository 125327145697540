import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGitAlt,
  faJsSquare,
  faPython,
  faCss3,
  faHtml5,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            My name is Leandro, I'm 26 years old, and I have a degree in Science
            and Technology as well as in Petroleum Engineering, both obtained at
            the Universidade Federal de São Paulo - UNIFESP.
          </p>
          <p>
            {' '}
            I have been working on data analysis/data science projects for over
            4 years and also have experience with data visualization tools. I
            previously worked as a junior analyst at a major energy trading
            company, and I currently leverage my data expertise to create
            technical documents and deliver services with accuracy and
            efficiency.
          </p>
          <p>
            If I had to define myself, I would say that I am very committed and
            dedicated in everything I do, considering these qualities as
            essential pillars that enable me to learn quickly and effectively
            tackle various challenges.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faPython} color="#223659" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
