import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules'
import 'swiper/scss'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import slide_image_1 from '../../../assets/images/img_9.png'
import slide_image_2 from '../../../assets/images/img_2.png'
import slide_image_3 from '../../../assets/images/img_3.png'
import slide_image_4 from '../../../assets/images/img_12.png'
import slide_image_5 from '../../../assets/images/img_14.png'
import slide_image_6 from '../../../assets/images/img_10.png'
import slide_image_7 from '../../../assets/images/img_11.png'
import slide_image_8 from '../../../assets/images/img_13.png'

import './index.scss'

const Slider2 = () => {
  return (
    <div className="container-slider2">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination2', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container2"
        initialSlide={'img_9'}
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_8} alt="slide_image" />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Slider2
